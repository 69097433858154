<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ $t('find-password') }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-row type="flex" justify="space-around" align="middle" :style="{ margin:'80px auto'}">
      <a-col :span="8">
        <a-typography-title :level="4" :style="{textAlign:'center',marginBottom:'64px'}">{{ $t('find-password') }}</a-typography-title>
        <a-form name="form-find-pwd" layout="horizontal" :colon="false" :hideRequiredMark="true" :model="formState" :rules="formRules" autocomplete="off" @finish="submitResetPwd">
          <a-form-item name="UserEmail" :label="$t('user_email')">
            <a-input v-model:value="formState.UserEmail"/>
          </a-form-item>
          <a-form-item name="VeriCode">
            <a-input-group compact>
              <a-input v-model:value="formState.VeriCode" style="width: 50%" :maxlength="6" :placeholder="$t('vercode')"/>
              <a-button :disabled="!isEmail(formState.UserEmail) || isDisabled" @click="SendEmailCode" style="width: 50%">{{ sendCodeText }}</a-button>
            </a-input-group>
          </a-form-item>
          <a-form-item name="Password" :label="$t('new_password')">
            <a-input-password v-model:value="formState.Password"/>
          </a-form-item>
          <a-form-item name="RePassword" :label="$t('re_new_password')">
            <a-input-password v-model:value="formState.RePassword"/>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" block :loading="submitLoading">{{$ta('reset|password','p')}}</a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {isEmail} from "@/utils/util";
import {sendVeriCode,resetPassword} from "@/services/account";
import {inject} from "vue";

export default {
  name: "FindPassword",
  components: {},
  data() {
    return {
      selectedMenuKeys: inject('selectedMenuKeys'),
      formState: {
        UserEmail: '',
        VeriCode: '',
        Password: '',
        RePassword: '',
      },
      formRules: {
        UserEmail: [{
          required: true,
          type: 'email',
          message: '',
        }],
        VeriCode: [{
          required: true,
          message: '',
        }],
        Password: [{
          required: true,
          asyncValidator: async (_rule, value) => {
            if (value === '') {
              return Promise.reject('');
            } else if(value.length < 10){
              return Promise.reject(this.$t("passwords_min_length"));
            } else {
              return Promise.resolve();
            }
          },
        }],
        RePassword: [{
          required: true,
          asyncValidator: async (_rule, value) => {
            if (value === '') {
              return Promise.reject('');
            } else if (value !== this.formState.Password) {
              return Promise.reject(this.$t("passwords_not_match"));
            } else {
              return Promise.resolve();
            }
          },
        }],
      },
      sendCodeText: this.$t('send_email_code'),
      maxResendCodeTime: 60,
      resendCodeTime: 60,
      resendTimer: null,
      isDisabled: false,
      submitLoading: false
    }
  },
  beforeMount() {
    this.selectedMenuKeys = []
  },
  beforeUnmount(){
    if(this.resendTimer > 0)
      clearInterval(this.resendTimer)
  },
  methods: {
    isEmail,
    SendEmailCode:function(){
      if(this.formState.UserEmail === '')
        return
      if(!isEmail(this.formState.UserEmail))
        return

      this.isDisabled = true
      sendVeriCode(this.formState.UserEmail, this.$store.getters["setting/getLanguage"]).then(res => {
        const resData = res.data
        if (resData.Success) {
          this.$message.success(this.$t('send_email_code_success'))
          this.setCountdown()
          this.resendTimer = setInterval(this.setCountdown, 1000)
        } else {
          this.$message.error(this.t('error.' + resData.Code))
          this.isDisabled = false
        }
      }).catch(error => {
        console.log(error)
        this.isDisabled = false
      })
    },
    setCountdown : function() {
      if(this.resendCodeTime <= 0) {
        clearInterval(this.resendTimer)
        this.sendCodeText = this.$t('send_email_code')
        this.resendCodeTime = this.maxResendCodeTime
        this.isDisabled = false
      }else{
        this.sendCodeText = this.$t('resend_after_s', {s: this.resendCodeTime})
        --this.resendCodeTime
      }
    },
    submitResetPwd(){
      this.submitLoading = true
      resetPassword(this.formState.UserEmail, this.formState.VeriCode, this.formState.Password).then(res => {
        this.submitLoading = false
        const resData = res.data
        if (resData.Success) {
          var _this = this;
          this.$message.success(this.$t('success'), 2, function (){
            _this.$router.push({name: 'home'})
          })
        } else {
          this.$message.error(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>